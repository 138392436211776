import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Page/Home";
import About from "./Page/About";
import Services from "./Page/Services";
import Gallery from "./Page/Gallery";
import Contact from "./Page/Contact";
import ServicesDetail from "./components/Services/ServicesDetail";
import Loader from "./components/global/Loader";
import axios from "axios";
import "./index.css";
import "./css/styles.css";
import "./css/slider.css";
import "./css/socialmediaicon.css";
import { GlobalDataContext } from "./context/context";
import BackToTop from "./components/global/BackToTop";

const App = () => {
  const id = "6333215f505d997a8b62c266";
  const [rpdata, setrpdata] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  //useEffect para peticion al servidor
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await axios({
          baseURL: "https://servidorpublico.herokuapp.com/api",
          url: `/paginas/${id}`,
          method: "get",
        });
        //se convierte la data en el objeto
        setrpdata(JSON.parse(JSON.stringify(response.data)));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    // Wait for 3 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="App">
      <GlobalDataContext.Provider value={{ rpdata }}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />}></Route>
            <Route path="/:id" element={<ServicesDetail />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <BackToTop />
        </Router>
      </GlobalDataContext.Provider>
    </div>
  );
};

export default App;
